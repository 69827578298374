:root {
  --color-blue-50: 226, 247, 249;
  --color-blue-100: 79, 189, 244;
  --color-blue-200: 64, 137, 201;
  --color-blue-300: 51, 111, 164;
  --color-blue-400: 39, 97, 147;
  --color-blue-500: 29, 82, 128;
  --color-blue-600: 23, 63, 99;

  --color-black: 0, 0, 0;
  --color-white: 255, 255, 255;
  --color-white-100: 249, 252, 252;
  --color-white-200: 241, 245, 245;
  --color-white-300: 223, 229, 232;
  --color-white-400: 213, 221, 225;
  --color-white-500: 190, 200, 205;

  --color-gray: 143, 143, 143;
  --color-gray-aa-secondary: 112, 112, 112;
  --color-gray-decorative: 237, 237, 237;
  --color-gray-primary-dark: 40, 40, 40;

  --color-red-300: 235, 82, 77;

  --color-shadow-50: 0, 0, 0;

  --color-active: 15, 199, 104;

  --button-radius: 10em;
  --radius-element: 0.4em;
}
