input.default,
textarea.default {
  padding: 0.45em 0;
  font-size: var(--art-font-size-14);
  font-family: var(--art-font-family-body);

  border-radius: var(--art-border-radius-lg);
  border: 1px solid var(--art-color-border-stark);
  background: var(--art-color-mono-white);
}

textarea.default {
  padding: 0.4em;
  border-radius: var(--radius-element);
  border: 1px solid rgba(var(--color-gray-decorative), 1);
}

textarea.default:focus {
  outline: 0;
  border-color: rgba(var(--color-blue-100), 1);
}

label.textareaLabel {
  color: rgba(var(--color-gray-aa-secondary), 1);
  transform-origin: left;
  transform: scale(0.8);
  font-weight: 600;
  letter-spacing: 0.03em;
}

// ---- float labels ---- //
.inputFloatLabel {
  --font-size: var(--art-font-size-14);
  --input-padding: 0.15em;

  color: rgba(var(--color-gray-aa-secondary), 1);
  display: flex;
  flex-direction: column-reverse;
  margin-bottom: 1em;
}

.inputFloatLabel input {
  outline: 0;
  color: rgba(var(--color-gray-primary-dark), 1);

  height: 40px;
  padding: var(--art-space-1) var(--art-space-1-5);
  align-items: center;
  gap: var(--art-space-0-75);
  align-self: stretch;
  margin-top: var(--art-space-0-5);

  font-size: var(--font-size);
}

.inputFloatLabel input:focus {
  border-color: rgba(var(--color-blue-100), 1);
}

.inputFloatLabel label {
  color: var(--art-color-text-primary);
}

.inputFloatLabel input:placeholder-shown + label {
  max-width: 60%;
  cursor: text;
}

// ---- float labels ---- //

.textAreaLabel {
  color: var(--art-color-text-primary);
}

.textAreaLabel textarea:focus + label {
  color: var(--art-color-text-primary);
  line-height: 1.25rem;
  cursor: default;
  letter-spacing: 0.03em;
}

.iconButton {
  display: flex;

  align-items: center;

  border-radius: var(--button-radius);
  padding: .5rem 1rem;
  margin: 0.5em 0;
  letter-spacing: 0.075em;
  text-transform: uppercase;
  transition: box-shadow 0.2s ease-in-out;

  &:hover {
    box-shadow: 0 0 0 1px rgba(var(--color-white-300), 1);
  }

  &:active {
    box-shadow: inset 0 0 4px 1px rgba(var(--color-white-400), 1);
  }

  .filePickerInput {
    visibility: hidden;
  }

  .iconButtonIcon {
    width: 1.2em;
    height: 1.2em;
    margin-inline-end: 0.5em;
  }

  .iconButtonText {
    line-height: 1rem;
  }
}

button.iconButton {
  border: 0;
  background-color: transparent;
}

.validationMessage {
  color: rgba(var(--color-red-300), 1);
  margin-top: 0.25em;
  transition: opacity 0.3s ease-in-out;
}

.validationMessage.hidden {
  opacity: 0;
}
