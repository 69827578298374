.main {
  display: flex;
  height: 100vh;
  justify-content: center;
  align-items: center;

  @media (max-height: 750px) {
    height: unset;
  }
}
