@layer variables {
  @import 'variables';
}

@layer inputs {
  @import 'inputs';
}

@layer app {
  :root {
    --art-font-size-base: 0.668153;
  }

  html,
  body {
    padding: 0;
    margin: 0;
    font-size: var(--art-font-size-14);
  }

  html {
    height: 100%;
  }

  body {
    min-height: 100%;
    display: flex;
  }

  #__next {
    display: flex;
    flex-direction: column;
    flex: 1;
  }

  a {
    color: inherit;
    text-decoration: none;
  }

  * {
    box-sizing: border-box;
  }
}

@import '@articulate/design-system-tokens';
